const slider = {};

function initiateSlideshow(element, index, id) {
  const options = {
    effect: 'slide',
    slidesPerView: 1,
    navigation: {
      nextEl: `.carousel__btn-next-${id}`,
      prevEl: `.carousel__btn-prev-${id}`,
    },
    breakpoints: {
      650: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1000: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1300: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
  };

  // initialize the swiper and add it to the array
  slider[index] = new Swiper(element, options);
}

export default function statsComponent() {
  const slideShows = document.querySelectorAll('.carousel--stats');

  slideShows.forEach((slideshow, index) => {
    const numberOfSlides = slideshow.querySelectorAll('.swiper-slide').length;
    const carousel = slideshow.querySelector('.carousel-wrapper');
    const id = carousel.dataset.id;

    if (numberOfSlides > 4) {
      initiateSlideshow(carousel, index, id);
    }
  });
}

document.addEventListener('DOMContentLoaded', function () {
  statsComponent();
});

if (window.acf) {
  window.acf.addAction('render_block_preview', statsComponent);
}
